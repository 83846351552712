import request from '@/utils/request'

/**
 * 查询教师信息
 */
export function getTeachersApi(params){
    return request({
        method:'GET',
        url:'/teachers/getTeachers',
        params
    })
}
/**
 * 增加教师信息
 */
export function addTeachersApi(data){
    return request({
        method:'POST',
        url:'/teachers/addTeachers',
        data
    })
}
/**
 * 删除教师信息
 */
export function deleteTeachersApi(_id){
    return request({
        method:'DELETE',
        url:'/teachers/deleteTeachers',
        data:{_id}
    })
}
