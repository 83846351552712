import axios from 'axios'
//设置基础路径
axios.defaults.baseURL=process.env.VUE_APP_BASE_URL
//设置超时时间
axios.defaults.timeout=10000
//设置请求拦截器,请求拦截器的参数两个，参数1为请求的配置方法，请求2为错误的处理函数
axios.interceptors.request.use((config)=>{
    // config.headers['Authorization']="token的值"
    config.headers['Content-Type']="application/x-www-form-urlencoded"
    return config
},(err)=>{
    return Promise.reject(err)
})
//设置响应拦截器,参数1：响应的处理函数,参数2为错误的处理函数
axios.interceptors.response.use((response)=>{
    return response.data
},(err)=>{
    if(err.response.status){
        switch(err.response.status){
            case 404:
                break;
            case 400:
                break;
            case 401:
                break;
            case 500:
                break;
        }
    }
    return Promise.reject(err)
})
export default axios
