import request from '@/utils/request'
//注册账号
export function doRegisterApi(data){
    return request({
        method:'POST',
        url:'/users/register',
        data
    })
}

//登陆账号
export function doLoginApi(data){
    return request({
        method:'POST',
        url:'/users/login',
        data
    })
}

//获取用户数据
export function getUserInfoApi(params){
    return request({
        url:'/users/getUserInfo',
        params
    })
}

//获取或查询用户数据
export function getUsersApi(params){
    return request({
        url:'/users/getUsers',
        params
    })
}

//修改用户数据
export function updateUsersApi(params){
    return request({
        method:'PUT',
        url:'/users/updateUsers',
        params
    })
}

//通过id获取用户数据
export function getUsersByIdApi(params){
    return request({
        url:'/users/getUsersById',
        params
    })
}