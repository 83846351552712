import request from '@/utils/request'
/**
 * 查询学生的api接口
 * @param {*} params 
 * @returns 
 */
//获取学生信息
export function getStudentsApi(params){
    return request({
        url:'/students/getStudents',
        params
    })
}
/**
 * 增加学生api接口
 * @param {} data 
 * @returns 
 */
//增加学生信息
export function addStudentApi(data){
    return request({
        method:'POST',
        url:'/students/addStudents',
        data
    })
}
//删除学生信息
export function delStudentApi(data){
    return request({
        method:'DELETE',
        url:'/students/deleteStudents',
        data:{_id}
    })
}
//更新学生信息
export function updateStudentApi(data){
    return request({
        method:'PUT',
        url:'/students/updateStudents',
        data
    })
}
