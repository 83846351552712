/* 
  路由配置对象属性如下
   path：路由的路径，用户通过地址来访问路由中的组件
   component：组件
*/
 export default [
    {
        path:'/login',
        component:()=>import('@/views/Login.vue')
    },
    {
        path:'/register',
        component:()=>import('@/views/Register.vue')
    },
    {
        path:'/home',
        component:()=>import('@/views/Home.vue'),
        children:[
            {
                path:'director',
                component:()=>import('@/views/Director.vue')
            },
            {
                path:'students',
                component:()=>import('@/views/Students.vue')
            },
            {
                path:'teacher',
                component:()=>import('@/views/Teacher.vue')
            },
            {
                path:'subjects',
                component:()=>import('@/views/Subjects.vue')
            },
            {
                path:'class',
                component:()=>import('@/views/Class.vue')
            }, 
            {
                path:'outher',
                component:()=>import('@/views/Outher.vue')
            },
             {
                path:'users',
                component:()=>import('@/views/Users.vue')
            }


        ]
    },
    {
        path:'/layout',
        component:()=>import('@/views/layout/Index.vue'),
    }
 ]