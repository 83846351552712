import request from '@/utils/request'

/**
 * 查询课程信息
 */
export function getCoursesApi(params){
    return request({
        method:'GET',
        url:'/courses/getCourses',
        params
    })
}
/**
 * 增加课程信息
 */
export function addCoursesApi(data){
    return request({
        method:'POST',
        url:'/courses/addCourses',
        data
    })
}
/**
 * 删除课程信息
 */
export function deleteCoursesApi(_id){
    return request({
        method:'DELETE',
        url:'/courses/deleteCourses',
        data:{_id}
    })
}
