import Vue from 'vue'
//引入elementui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//全局挂载api
import api from '@/api'
Vue.prototype.$api=api
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
