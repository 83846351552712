import request from '@/utils/request'

/**
 * 查询专业信息
 */
export function getSubjectsApi(params){
    return request({
        method:'GET',
        url:'/subjects/getSubjects',
        params
    })
}
/**
 * 增加专业信息
 */
export function addSubjectsApi(data){
    return request({
        method:'POST',
        url:'/subjects/addSubjects',
        data
    })
}
/**
 * 删除专业信息
 */
export function deleteSubjectsApi(_id){
    return request({
        method:'DELETE',
        url:'/subjects/deleteSubjects',
        data:{_id}
    })
}
