import request from '@/utils/request'

/**
 * 查询班级信息
 */
export function getClassesApi(params){
    return request({
        method:'GET',
        url:'/classes/getClasses',
        params
    })
}
/**
 * 增加班级信息
 */
export function addClassesApi(data){
    return request({
        method:'POST',
        url:'/classes/addClasses',
        data
    })
}
/**
 * 删除班级信息
 */
export function deleteClassesApi(_id){
    return request({
        method:'DELETE',
        url:'/classes/deleteClasses',
        data:{_id}
    })
}
