//导入Vue
import Vue from 'vue'
//导入VueRouter
import VueRouter from 'vue-router'
import routes from '@/router/routes'
//设置VueRouter到Vue中
Vue.use(VueRouter)
//创建vueRouter对象,参数是一个配置对象,配置对象中常见的属性如下
// mode:路由模式，路由模式常见有两种，一种是hash模式,另外一种是history
// routes:路由配置集合,它是一个数组，该数组中用来管理路由配置对象的
const router=new VueRouter({
    mode:'hash',
    routes
})
export default router