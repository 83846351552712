import * as students from '@/api/students'
import * as director from '@/api/director'
import * as subjects from '@/api/subjects'
import * as classes from '@/api/classes'
import * as teachers from '@/api/teacher'
import * as courses from '@/api/courses'
import * as users from '@/api/users'
export default{
    students,
    director,
    subjects,
    classes,
    teachers,
    courses,
    users
}