import request from '@/utils/request'

/**
 * 查询班主任信息
 */
export function getDirectorsApi(params){
    return request({
        method:'GET',
        url:'/directors/getDirectors',
        params
    })
}
/**
 * 增加班主任信息
 */
export function addDirectorsApi(data){
    return request({
        method:'POST',
        url:'/directors/addDirectors',
        data
    })
}
/**
 * 删除班主任信息
 */
export function deleteDirectorsApi(_id){
    return request({
        method:'DELETE',
        url:'/directors/deleteDirectors',
        data:{_id}
    })
}
/**
 * 修改班主任信息
 */
export function updateDirectorsApi(data){
    return request({
        method:'PUT',
        url:'/directors/updateDirectors',
        data
    })
}